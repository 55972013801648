<template>
  <div class="container">
    <BaseH1 :text="'Czy masz właszne dzieci?'" class="h1 container__h1" />

    <InputRadioGroupHorizontal
      v-model="application.isExperienceOwnKids"
      :name="'is-experience-own-kids'"
      :options="optionsIsExperienceOwnKids"
      :width-small="true"
    />

    <TextHint :text="'Jest schowane, jeśli nie masz'" />
  </div>
</template>

<script>
import { watch } from "@vue/runtime-core";
import { reactive } from "@vue/reactivity";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import InputRadioGroupHorizontal from "@/components/UI/Base/InputRadioGroupHorizontal.vue";
import TextHint from "@/components/UI/TextHint.vue";

export default {
  components: {
    BaseH1,
    InputRadioGroupHorizontal,
    TextHint,
  },

  props: {
    isExperienceOwnKids: Boolean,
  },

  emits: ["push:experienceOwnKids", "pull:experienceOwnKids"],

  setup(props, { emit }) {
    const application = reactive({
      isExperienceOwnKids: props.isExperienceOwnKids,
    });

    watch(
      () => application.isExperienceOwnKids,
      (newIsExperienceOwnKids) => {
        if (newIsExperienceOwnKids === true) {
          emit("push:experienceOwnKids");
        }

        if (newIsExperienceOwnKids === false) {
          emit("pull:experienceOwnKids");
        }
      }
    );
    watch(
      () => props.isExperienceOwnKids,
      (newIsExperienceOwnKids) => {
        application.isExperienceOwnKids = newIsExperienceOwnKids;
      }
    );

    const optionsIsExperienceOwnKids = [
      {
        label: "Tak, mam",
        value: true,
      },
      {
        label: "Nie mam",
        value: false,
      },
    ];

    return {
      application,
      optionsIsExperienceOwnKids,
    };
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 80px;
}
.container__h1 {
  width: 420px;
  margin-top: -20px;
}
.wrapper-buttons {
  display: flex;
  flex-flow: row;
  column-gap: 40px;
}

@media (max-width: 1200px) {
  .container {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 80px;
  }
  .container__h1 {
    width: 100%;
    margin-top: 0;
  }
  .wrapper-buttons {
    display: flex;
    flex-flow: row;
    column-gap: 40px;
  }
}
</style>
