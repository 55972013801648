<template>
  <div class="nav-mobile-wrapper">
    <nav class="nav-mobile">
      <BaseButtonIconArrowBack
        @click="$router.back()"
        :size="'small'"
        class="button-icon nav-mobile__button-icon"
      />
      <ButtonPrimarySave
        v-if="isSaveButtonVisible"
        @click="$emit('save')"
        :size="'x-small'"
        :isDisabled="isSaveButtonDisabled"
      />
    </nav>
  </div>
</template>

<script>
import BaseButtonIconArrowBack from "@/components/UI/Base/BaseButtonIconArrowBack.vue";
import ButtonPrimarySave from "@/components/UI/ButtonPrimarySave.vue";

export default {
  components: { BaseButtonIconArrowBack, ButtonPrimarySave },

  props: {
    isSaveButtonVisible: Boolean,
    isSaveButtonDisabled: Boolean,
  },
};
</script>

<style scoped>
.nav-mobile-wrapper {
  width: 100vw;

  position: fixed;
  bottom: 0;
  left: 0;

  background-color: var(--main-bg-color);
  border-top: 1px solid rgba(22, 32, 94, 0.06);
}
.nav-mobile {
  width: var(--layout-default--width);
  height: 50px;

  margin: 0 auto;

  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}
.nav-mobile__button-icon {
  width: calc((100% - 3 * 10px) / 4);
  opacity: 0.4;
}
.nav-mobile__button-icon:active {
  opacity: 1;
}
</style>
