<template>
  <div class="container">
    <BaseH1 :text="'Mnie pasuje...'" />

    <div class="wrapper-buttons">
      <InputRadioGroupVertical
        v-model="application.employmentTypes"
        :name="'employment-type'"
        :options="optionsEmploymentTypes"
      />
      <TextHint
        :text="`Pelen etat — 8 i więcej godzin dziennie
        Część etatu — mniej niż 8 godzin dziennie, co również dotyczy babysittinga`"
      />
    </div>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import InputRadioGroupVertical from "@/components/UI/Base/InputRadioGroupVertical.vue";
import TextHint from "@/components/UI/TextHint.vue";

export default {
  components: {
    BaseH1,
    InputRadioGroupVertical,
    TextHint,
  },

  props: {
    employmentTypes: Array,
  },

  emits: ["update:employmentTypes"],

  setup(props, { emit }) {
    const application = reactive({
      employmentTypes: props.employmentTypes,
    });

    watch(
      () => application.employmentTypes,
      (newEmploymentTypes) =>
        emit("update:employmentTypes", newEmploymentTypes),
      { deep: true }
    );
    watch(
      () => props.employmentTypes,
      (newEmploymentTypes) =>
        (application.employmentTypes = newEmploymentTypes),
      { deep: true }
    );

    const optionsEmploymentTypes = [
      {
        label: "Pełen etat",
        value: ["full_time"],
      },
      {
        label: "Część etatu",
        value: ["part_time"],
      },
      {
        label: "Obie opcji",
        value: ["full_time", "part_time"],
      },
    ];

    return {
      application,
      optionsEmploymentTypes,
    };
  },
};
</script>

<style scoped>
.container {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 80px;
}
.wrapper-buttons {
  display: flex;
  flex-flow: column;
  align-items: center;
  column-gap: 40px;
  row-gap: 40px;
}

@media (max-width: 1200px) {
}
</style>
