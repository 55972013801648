<template>
  <div class="container">
    <BaseH1
      :text="'Czy masz doświadczenie zawodowe z dziećmi?'"
      class="h1 container__h1"
    />

    <InputRadioGroupHorizontal
      v-model="application.isExperienceWorking"
      :name="'is-experience-working'"
      :options="optionsIsExperienceWorking"
      :width-small="true"
    />

    <TextHint :text="'Jest schowane, jeśli nie masz'" />
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import InputRadioGroupHorizontal from "@/components/UI/Base/InputRadioGroupHorizontal.vue";
import TextHint from "@/components/UI/TextHint.vue";

export default {
  components: {
    BaseH1,
    InputRadioGroupHorizontal,
    TextHint,
  },

  props: {
    isExperienceWorking: Boolean,
  },

  emits: ["update:isExperienceWorking"],

  setup(props, { emit }) {
    const application = reactive({
      isExperienceWorking: props.isExperienceWorking,
    });

    watch(
      () => application.isExperienceWorking,
      (newIsExpWorking) => emit("update:isExperienceWorking", newIsExpWorking)
    );
    watch(
      () => props.isExperienceWorking,
      (newIsExpWorking) => (application.isExperienceWorking = newIsExpWorking)
    );

    const optionsIsExperienceWorking = [
      {
        label: "Tak, mam",
        value: true,
      },
      {
        label: "Nie mam",
        value: false,
      },
    ];

    return {
      application,
      optionsIsExperienceWorking,
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 80px;
}
.container__h1 {
  width: 420px;
  margin-top: -20px;
}
.wrapper-buttons {
  display: flex;
  flex-flow: row;
  column-gap: 40px;
}

@media (max-width: 1200px) {
  .container {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 80px;
  }
  .container__h1 {
    width: 100%;
    margin-top: 0;
  }
  .wrapper-buttons {
    display: flex;
    flex-flow: column;
    row-gap: 40px;
  }
}
</style>
