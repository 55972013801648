<template>
  <div class="nav-side-bar-left">
    <div
      v-for="link in links"
      :key="link.label"
      class="links-group nav-side-bar-left__links-group"
    >
      <ButtonLink
        :to="link.to"
        :label="link.label"
        class="link-main links-group__link-main"
        :class="link.isActive ? 'is-active' : ''"
      />
      <div
        v-if="(link.isActive || link.isActiveSublink) && link.sublinks?.length"
        class="sublinks links-group__sublinks"
      >
        <ButtonLink
          v-for="sublink in link.sublinks"
          :key="sublink.label"
          :to="sublink.to"
          :label="sublink.label"
          class="link sublinks__link"
          :class="sublink.isHidden ? 'hidden' : ''"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonLink from "@/components/UI/Base/ButtonLink.vue";

export default {
  components: { ButtonLink },
  props: {
    links: Array,
  },
};
</script>

<style scoped>
.nav-side-bar-left {
  width: 100%;

  display: flex;
  flex-flow: column;
  row-gap: 30px;
}
.nav-side-bar-left__links-group {
  display: flex;
  flex-flow: column;
  row-gap: 5px;
}
.links-group__link-main {
  font: var(--h3--font);
}
.links-group__sublinks {
  padding-left: 15px;

  display: flex;
  flex-flow: column;
  row-gap: 5px;
}
.links-group__sublinks:deep(.sublinks__link) {
  font-size: 16px;
  font-weight: 300;
}
</style>
