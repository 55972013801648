<template>
  <block-content-13>
    <template #left>
      <div class="content__container-left-fixed">
        <NavSideBarLeft :links="links" />
      </div>
    </template>
    <template #right>
      <div class="content__container-right">
        <div id="address" class="wrapper-section">
          <ApplicationNannyInputAddress
            v-model:address="applicationNanny.details.location.address"
            v-model:coordinates-lat-lng="coordsLatLng"
            @update:is-valid="isValid = $event"
            :zoom="16"
          />
        </div>
        <div id="aupair" class="wrapper-section">
          <ApplicationNannyInputAupairRadio
            v-model:aupair="applicationNanny.details.aupair"
            @update:is-valid="isValid = $event"
          />
        </div>
        <div id="working-hours" class="wrapper-section">
          <ApplicationNannyInputEmploymentTypeRadio
            id="employment-type"
            v-model:employment-types="applicationNanny.employment.types"
          />
        </div>
        <div
          v-if="applicationNanny.employment.types.includes('part_time')"
          id="employment-regularity"
          class="wrapper-section"
        >
          <ApplicationNannyInputEmploymentRegularity
            v-model:employment-regularity="
              applicationNanny.employment.regularities
            "
          />
        </div>
        <div id="schedule" class="wrapper-section">
          <ApplicationNannyInputSchedule
            v-model:schedule-value="applicationNanny.schedule.value"
            v-model:schedule-comment="applicationNanny.schedule.comment"
            v-model:schedule-date-start="applicationNanny.schedule.dateStart"
            @update:is-valid="isValid = $event"
          />
        </div>
        <div id="salary" class="wrapper-section">
          <ApplicationNannyInputSalary
            v-model:fee-hour="applicationNanny.details.rate.hour"
            v-model:fee-month="applicationNanny.details.rate.month"
            @update:is-valid="isValid = $event"
          />
        </div>
        <div id="experience" class="wrapper-section">
          <ApplicationNannyInputExperienceWorkingRadio
            id="experience-working"
            v-model:is-experience-working="
              applicationNanny.experience.working.isPresent
            "
          />
        </div>
        <div
          v-if="applicationNanny.experience.working.isPresent === true"
          class="wrapper-section"
        >
          <ApplicationNannyInputExperienceWorkingKidsAges
            v-model:kids-ages="applicationNanny.experience.working.kidsAges"
          />
        </div>
        <div
          v-if="applicationNanny.experience.working.isPresent === true"
          class="wrapper-section"
        >
          <ApplicationNannyInputExperienceWorkingYears
            v-model:experience-years="applicationNanny.experience.working.years"
            v-model:experience-comment="
              applicationNanny.experience.working.comment
            "
            @update:is-valid="isValid = $event"
          />
        </div>
        <div id="experience-life" class="wrapper-section">
          <ApplicationNannyInputExperienceOwnKidsRadio
            :is-experience-own-kids="
              applicationNanny.experience.life.includes('own_kids')
            "
            @push:experience-own-kids="experienceLifePush('own_kids')"
            @pull:experience-own-kids="experienceLifePull('own_kids')"
          />
        </div>
        <div class="wrapper-section">
          <ApplicationNannyInputExperienceTwinsRadio
            :is-experience-twins="
              applicationNanny.experience.life.includes('twins')
            "
            @push:experience-twins="experienceLifePush('twins')"
            @pull:experience-twins="experienceLifePull('twins')"
          />
        </div>
        <div class="wrapper-section">
          <ApplicationNannyInputExperienceSpecialCareRadio
            :is-experience-special-care="
              applicationNanny.experience.life.includes('special_care')
            "
            @push:experience-special-care="experienceLifePush('special_care')"
            @pull:experience-special-care="experienceLifePull('special_care')"
          />
        </div>
        <div id="education" class="wrapper-section">
          <ApplicationNannyInputEducationDegree
            id="education-degree"
            v-model:education-degree="applicationNanny.education.degree"
          />
        </div>
        <div
          v-if="applicationNanny.education.degree !== 'student'"
          id="education-type"
          class="wrapper-section"
        >
          <ApplicationNannyInputEducationType
            v-model:education-type="applicationNanny.education.types"
          />
        </div>
        <div
          v-if="applicationNanny.education.degree === 'student'"
          id="education-type"
          class="wrapper-section"
        >
          <ApplicationNannyInputEducationTypeStudent
            v-model:education-type="applicationNanny.education.types"
          />
        </div>
        <div id="education-courses" class="wrapper-section">
          <ApplicationNannyInputEducationCourses
            v-model:education-courses="applicationNanny.education.courses"
            v-model:education-comment="applicationNanny.education.comment"
          />
        </div>
        <div id="responsibilities" class="wrapper-section">
          <ApplicationNannyInputResponsibilities
            v-model:child-care="applicationNanny.responsibilities.careChild"
            v-model:activities="applicationNanny.responsibilities.activities"
            v-model:home-care="applicationNanny.responsibilities.careHome"
            @update:is-valid="isValid = $event"
          />
        </div>
        <div id="about" class="wrapper-section">
          <ApplicationNannyInputPerks
            id="about-perks"
            v-model:perks-advantages="applicationNanny.perks"
            v-model:perks-languages="applicationNanny.details.languages"
            @update:is-valid="isValid = $event"
          />
        </div>
        <div id="about-description" class="wrapper-section">
          <ApplicationNannyInputAboutMe
            v-model:about-me="applicationNanny.details.description"
            @update:is-valid="isValid = $event"
          />
        </div>
        <div id="photo" class="wrapper-section">
          <ApplicationNannyInputPhotoMain
            id="photo-main"
            v-model:imgCroppedSrc="applicationNanny.details.photoMain.src"
            @update:imgCroppedFile="
              applicationNanny.details.photoMain.file = $event
            "
            v-model:imgInputSrc="applicationNanny.details.photoMain.originalSrc"
            @update:imgInputFile="
              applicationNanny.details.photoMain.originalFile = $event
            "
            v-model:imgInputType="
              applicationNanny.details.photoMain.originalMimetype
            "
            v-model:imgInputName="
              applicationNanny.details.photoMain.originalFilename
            "
          />
        </div>
        <div id="photo-gallery" class="wrapper-section">
          <ApplicationNannyInputPhotoGallery
            :images="applicationNanny.details.photoGallery"
            @pull:image="pullPhotoGalleryImage($event)"
            @push:image="pushPhotoGalleryImage($event)"
          />
        </div>
        <div id="recommendations" class="wrapper-section">
          <ApplicationNannyInputRecommendations
            :recommendations="recommendations"
            @create="handleCreate"
            @update="handleUpdate"
            @delete="handleDelete"
          />
        </div>
      </div>
      <ButtonPrimarySave
        v-if="isStoreChanged && !isMobile"
        @click="updateApplicationNanny()"
        :isDisabled="!isValid"
        class="button-update button-update__container-right"
      />
      <NavMobileBackSave
        v-if="isMobile"
        @save="updateApplicationNanny()"
        :isSaveButtonVisible="isStoreChanged"
        :isSaveButtonDisabled="!isValid"
      />
    </template>
  </block-content-13>
</template>

<script>
import { computed, ref, toRefs } from "@vue/reactivity";
import { useRoute, useRouter } from "vue-router";
import { onMounted, onUnmounted, watch } from "vue";

import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { useApplicationNannyStore } from "@/stores/applicationNanny";

// import useApplicationNannyInputAddress from "@/composables/useApplicationNannyInputAddress";
import useApplicationNannyRecommendations from "@/composables/useApplicationNannyRecommendations";
import useCommonDictionary from "@/composables/useCommonDictionary";
import useGoogleMapsGeocoder from "@/composables/useGoogleMapsGeocoder";
import useCoordsReversed from "@/composables/useCoordsReversed";
import useIsMobile from "@/composables/useIsMobile";

import BlockContent13 from "@/layouts/BlockContent13.vue";
import NavSideBarLeft from "@/components/NavSideBarLeft.vue";
import ApplicationNannyInputAddress from "@/components/Application/Nanny/ApplicationNannyInputAddress.vue";
import ApplicationNannyInputAupairRadio from "@/components/Application/Nanny/ApplicationNannyInputAupairRadio.vue";
import ApplicationNannyInputEmploymentTypeRadio from "@/components/Application/Nanny/ApplicationNannyInputEmploymentTypeRadio.vue";
import ApplicationNannyInputEmploymentRegularity from "@/components/Application/Nanny/ApplicationNannyInputEmploymentRegularity.vue";
import ApplicationNannyInputSchedule from "@/components/Application/Nanny/ApplicationNannyInputSchedule.vue";
import ApplicationNannyInputSalary from "@/components/Application/Nanny/ApplicationNannyInputSalary.vue";
import ApplicationNannyInputExperienceWorkingRadio from "@/components/Application/Nanny/ApplicationNannyInputExperienceWorkingRadio.vue";
import ApplicationNannyInputExperienceWorkingKidsAges from "@/components/Application/Nanny/ApplicationNannyInputExperienceWorkingKidsAges.vue";
import ApplicationNannyInputExperienceWorkingYears from "@/components/Application/Nanny/ApplicationNannyInputExperienceWorkingYears.vue";
import ApplicationNannyInputExperienceOwnKidsRadio from "@/components/Application/Nanny/ApplicationNannyInputExperienceOwnKidsRadio.vue";
import ApplicationNannyInputExperienceTwinsRadio from "@/components/Application/Nanny/ApplicationNannyInputExperienceTwinsRadio.vue";
import ApplicationNannyInputExperienceSpecialCareRadio from "@/components/Application/Nanny/ApplicationNannyInputExperienceSpecialCareRadio.vue";
import ApplicationNannyInputEducationDegree from "@/components/Application/Nanny/ApplicationNannyInputEducationDegree.vue";
import ApplicationNannyInputEducationType from "@/components/Application/Nanny/ApplicationNannyInputEducationType.vue";
import ApplicationNannyInputEducationTypeStudent from "@/components/Application/Nanny/ApplicationNannyInputEducationTypeStudent.vue";
import ApplicationNannyInputEducationCourses from "@/components/Application/Nanny/ApplicationNannyInputEducationCourses.vue";
import ApplicationNannyInputResponsibilities from "@/components/Application/Nanny/ApplicationNannyInputResponsibilities.vue";
import ApplicationNannyInputPerks from "@/components/Application/Nanny/ApplicationNannyInputPerks.vue";
import ApplicationNannyInputAboutMe from "@/components/Application/Nanny/ApplicationNannyInputAboutMe.vue";
import ApplicationNannyInputPhotoMain from "@/components/Application/Nanny/ApplicationNannyInputPhotoMain.vue";
import ApplicationNannyInputPhotoGallery from "@/components/Application/Nanny/ApplicationNannyInputPhotoGallery.vue";
import ApplicationNannyInputRecommendations from "@/components/Application/Nanny/ApplicationNannyInputRecommendations.vue";
import ButtonPrimarySave from "@/components/UI/ButtonPrimarySave.vue";
import NavMobileBackSave from "@/components/NavMobileBackSave.vue";

export default {
  components: {
    BlockContent13,
    NavSideBarLeft,
    ApplicationNannyInputAddress,
    ApplicationNannyInputAupairRadio,
    ApplicationNannyInputEmploymentTypeRadio,
    ApplicationNannyInputEmploymentRegularity,
    ApplicationNannyInputSchedule,
    ApplicationNannyInputSalary,
    ApplicationNannyInputExperienceWorkingRadio,
    ApplicationNannyInputExperienceWorkingKidsAges,
    ApplicationNannyInputExperienceWorkingYears,
    ApplicationNannyInputExperienceOwnKidsRadio,
    ApplicationNannyInputExperienceTwinsRadio,
    ApplicationNannyInputExperienceSpecialCareRadio,
    ApplicationNannyInputEducationDegree,
    ApplicationNannyInputEducationType,
    ApplicationNannyInputEducationTypeStudent,
    ApplicationNannyInputEducationCourses,
    ApplicationNannyInputResponsibilities,
    ApplicationNannyInputPerks,
    ApplicationNannyInputAboutMe,
    ApplicationNannyInputPhotoMain,
    ApplicationNannyInputPhotoGallery,
    ApplicationNannyInputRecommendations,
    ButtonPrimarySave,
    NavMobileBackSave,
  },

  setup() {
    const isValid = ref(false);
    const applicationNanny = useApplicationNannyStore();
    const { isMobile } = useIsMobile();

    // Reset previous applicationNanny store
    applicationNanny.reset();

    const {
      details,
      employment,
      responsibilities,
      schedule,
      perks,
      experience,
      education,
    } = storeToRefs(applicationNanny);

    const { aupair, description, languages, location, rate } = toRefs(
      details.value
    );

    const experienceLifePush = (item) => {
      if (experience.value.life.some((elem) => elem === item)) return;
      experience.value.life.push(item);
    };

    const experienceLifePull = (item) => {
      const index = experience.value.life.findIndex((elem) => elem === item);
      if (index === -1) return;
      experience.value.life.splice(index, 1);
    };

    const route = useRoute();

    onMounted(() => {
      if (applicationNanny.storeMeta.isInitialized === false) {
        applicationNanny.initApplication(route.params.id);
      }
    });

    onUnmounted(() => {
      // Reset current applicationNanny store
      applicationNanny.reset();
    });

    const uploadPhotoMain = async () => {
      try {
        await applicationNanny.updatePhotoMain();
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    };

    watch(
      () => applicationNanny.details.photoMain.file,
      (photoMainCroppedFile) => {
        if (photoMainCroppedFile) uploadPhotoMain();
      }
    );

    const isStoreChanged = ref(false);
    // TODO: fix store self updating on the page load without 500ms timer
    // isPageInit with 500ms timer fixes store self updating on the page load
    const isPageInit = ref(false);
    watch(
      () => applicationNanny.storeMeta.isInitialized,
      (isInit) => {
        setTimeout(() => {
          isPageInit.value = isInit;
          console.log("Page init");
        }, 500);
      },
      {
        immediate: true,
      }
    );

    watch(
      [
        employment,
        responsibilities,
        schedule,
        perks,
        experience,
        education,
        aupair,
        description,
        languages,
        location,
        rate,
      ],
      () => {
        if (isPageInit.value === true) {
          isStoreChanged.value = true;
        }
      },
      { deep: true }
    );

    const { DICTIONARY_COMMON } = useCommonDictionary();
    const { coordsLatLngToCityName } = useGoogleMapsGeocoder();
    const { coordsReversed: coordsLatLng } = useCoordsReversed({
      coordsRef: computed(
        () => applicationNanny.details.location.point.coordinates
      ),
      setCoords: (newCoords) => {
        applicationNanny.details.location.point.coordinates = newCoords;
      },
    });

    const getUserCity = async () => {
      console.log("GET_USER_CITY_NAME");
      let cityName = null;

      try {
        cityName = await coordsLatLngToCityName(coordsLatLng.value);
      } catch (error) {
        console.log(error, "ERROR: -> RETURN_DEFAULT_CITY_FROM_MARKET");

        // Set default city from cityMarket
        const userCityMarketSlug = applicationNanny.details.location.cityMarket;
        cityName = DICTIONARY_COMMON[userCityMarketSlug];
      }

      return cityName;
    };

    const setUserCity = (cityName) => {
      console.log(`SET_CITY_TO_APPLICATION_LOCATION: ${cityName}`);
      applicationNanny.details.location.city = cityName;
    };

    const updateApplicationNanny = async () => {
      try {
        const userCity = await getUserCity();
        setUserCity(userCity);
        await applicationNanny.updateApplication();
        const user = useUserStore();
        await user.refetchUserStore();

        isStoreChanged.value = false;
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    };

    const pushPhotoGalleryImage = async (image) => {
      try {
        // Push image to photo gallery
        await applicationNanny.pushImageToPhotoGallery(image);
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    };

    const pullPhotoGalleryImage = async (image) => {
      try {
        // Pull image from photo gallery
        await applicationNanny.pullImageFromPhotoGallery(image);
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    };

    const router = useRouter();

    const {
      recommendations,
      // isLoading,
      handleCreate,
      handleUpdate,
      handleDelete,
    } = useApplicationNannyRecommendations(route.params.id);

    const setHash = (newHash) => {
      router.replace({ hash: newHash, params: { savePosition: true } });
    };
    setHash("#address");

    const currentHash = computed(() => {
      return route.hash;
    });

    const links = [
      {
        slug: "address",
        label: "Adres",
        to: "#address",
        get isActive() {
          return currentHash.value === this.to;
        },
      },
      {
        slug: "aupair",
        label: "Au Pair",
        to: "#aupair",
        get isActive() {
          return currentHash.value === this.to;
        },
      },
      {
        slug: "working_hours",
        label: "Grafik",
        to: "#working-hours",
        get isActive() {
          return currentHash.value === this.to;
        },
        get isActiveSublink() {
          return this.sublinks.some((sublink) => sublink.isActive);
        },
        sublinks: [
          {
            slug: "employment_type",
            label: "Etat",
            to: "#employment-type",
            get isActive() {
              return currentHash.value === this.to;
            },
          },
          {
            slug: "employment_regularity",
            label: "Regularność",
            to: "#employment-regularity",
            get isActive() {
              return currentHash.value === this.to;
            },
            get isHidden() {
              return (
                applicationNanny.employment.types.includes("part_time") ===
                false
              );
            },
          },
          {
            slug: "schedule",
            label: "Harmonogram",
            to: "#schedule",
            get isActive() {
              return currentHash.value === this.to;
            },
          },
        ],
      },
      {
        slug: "salary",
        label: "Wynagrodzenie",
        to: "#salary",
        get isActive() {
          return currentHash.value === this.to;
        },
      },
      {
        slug: "experience",
        label: "Doświadczenie",
        to: "#experience",
        get isActive() {
          return currentHash.value === this.to;
        },
        get isActiveSublink() {
          return this.sublinks.some((sublink) => sublink.isActive);
        },
        sublinks: [
          {
            slug: "experience_working",
            label: "Zawodowe",
            to: "#experience-working",
            get isActive() {
              return currentHash.value === this.to;
            },
          },
          {
            slug: "experience_life",
            label: "Życiowe",
            to: "#experience-life",
            get isActive() {
              return currentHash.value === this.to;
            },
          },
        ],
      },
      {
        slug: "education",
        label: "Edukacja",
        to: "#education",
        get isActive() {
          return currentHash.value === this.to;
        },
        get isActiveSublink() {
          return this.sublinks.some((sublink) => sublink.isActive);
        },
        sublinks: [
          {
            slug: "education_degree",
            label: "Poziom",
            to: "#education-degree",
            get isActive() {
              return currentHash.value === this.to;
            },
          },
          {
            slug: "education_type",
            label: "Typ wykształcenia",
            to: "#education-type",
            get isActive() {
              return currentHash.value === this.to;
            },
          },
          {
            slug: "education_courses",
            label: "Kursy",
            to: "#education-courses",
            get isActive() {
              return currentHash.value === this.to;
            },
          },
        ],
      },
      {
        slug: "responsibilities",
        label: "Obowiązki",
        to: "#responsibilities",
        get isActive() {
          return currentHash.value === this.to;
        },
      },
      {
        slug: "about",
        label: "O Ciebie",
        to: "#about",
        get isActive() {
          return currentHash.value === this.to;
        },
        get isActiveSublink() {
          return this.sublinks.some((sublink) => sublink.isActive);
        },
        sublinks: [
          {
            slug: "about_perks",
            label: "Zalety",
            to: "#about-perks",
            get isActive() {
              return currentHash.value === this.to;
            },
          },
          {
            slug: "about_description",
            label: "Opis",
            to: "#about-description",
            get isActive() {
              return currentHash.value === this.to;
            },
          },
        ],
      },
      {
        slug: "photo",
        label: "Zajęcia",
        to: "#photo",
        get isActive() {
          return currentHash.value === this.to;
        },
        get isActiveSublink() {
          return this.sublinks.some((sublink) => sublink.isActive);
        },
        sublinks: [
          {
            slug: "photo_main",
            label: "Main photo",
            to: "#photo-main",
            get isActive() {
              return currentHash.value === this.to;
            },
          },
          {
            slug: "photo_gallery",
            label: "Gallery",
            to: "#photo-gallery",
            get isActive() {
              return currentHash.value === this.to;
            },
          },
        ],
      },
      {
        slug: "recommendations",
        label: "Referencje",
        to: "#recommendations",
        get isActive() {
          return currentHash.value === this.to;
        },
      },
    ];

    return {
      isValid,
      applicationNanny,
      experienceLifePush,
      experienceLifePull,
      links,
      pushPhotoGalleryImage,
      pullPhotoGalleryImage,
      isStoreChanged,
      updateApplicationNanny,
      coordsLatLng,
      isMobile,

      recommendations,
      // isLoading,
      handleCreate,
      handleUpdate,
      handleDelete,
    };
  },
};
</script>

<style scoped>
.content__container-left-fixed {
  height: 100vh;

  display: flex;
  align-items: center;

  position: sticky;
  top: 0;
}
.content__container-right {
  margin: 80px 0;
  width: 100%;

  display: flex;
  flex-flow: column;
  row-gap: 80px;
}
.wrapper-section {
  width: 100%;

  padding: 60px 60px;

  display: flex;
  flex-flow: column;
  align-items: center;

  border-radius: var(--layout-view-wrapper--border-radius);
  background-color: var(--layout-view-wrapper--bg-color);
}
.button-update__container-right {
  position: fixed;
  z-index: 99;
  bottom: 60px;
}

@media (max-width: 1200px) {
  .content__container-left-fixed {
    height: 100vh;

    display: flex;
    align-items: center;

    position: sticky;
    top: 0;
  }
  .content__container-right {
    margin: 80px 0;
    width: 100%;

    display: flex;
    flex-flow: column;
    row-gap: 80px;
  }
  .wrapper-section {
    width: 100%;

    padding: 0;

    display: flex;
    flex-flow: column;
    align-items: center;

    border-radius: var(--layout-view-wrapper--border-radius);
    background-color: var(--layout-view-wrapper--bg-color);
  }
}
</style>
