<template>
  <div class="container">
    <!-- SCHEDULE SECTION -->
    <div class="wrapper-h1">
      <BaseH1 :text="'Wybierz pory dnia'" />
      <TextDescription :text="'Kiedy masz czas?'" />
    </div>

    <div class="wrapper-schedule">
      <ScheduleInput
        v-model="schedule.value"
        @update:is-valid-schedule="schedule.isValid = $event"
      />
      <InputTextTextareaScheduleComment
        v-model="schedule.comment"
        class="wrapper-schedule__comment-schedule"
      />
    </div>

    <!-- START DATE SECTION -->
    <BaseH1 :text="'Kiedy możesz zacząć pracę?'" />

    <div class="wrapper-date-start">
      <InputRadioGroupDatePicker
        v-model="schedule.dateStart.string"
        @update:is-valid-date="schedule.dateStart.isValid = $event"
        :name="'date-start'"
        :option-date="optionDateToday"
      />
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";
import { dateToString, getTodayDateString, stringToDate } from "@/helpers";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import TextDescription from "@/components/UI/TextDescription.vue";
import ScheduleInput from "@/components/ScheduleInput.vue";
import InputTextTextareaScheduleComment from "@/components/UI/InputTextTextareaScheduleComment.vue";
import InputRadioGroupDatePicker from "@/components/UI/InputRadioGroupDatePicker.vue";

export default {
  components: {
    BaseH1,
    TextDescription,
    ScheduleInput,
    InputTextTextareaScheduleComment,
    InputRadioGroupDatePicker,
  },

  props: {
    scheduleValue: Array,
    scheduleComment: String,
    scheduleDateStart: [String, Date],
  },

  emits: [
    "update:scheduleValue",
    "update:scheduleComment",
    "update:scheduleDateStart",
    "update:isValid",
  ],

  setup(props, { emit }) {
    const schedule = reactive({
      value: props.scheduleValue,
      isValid: false,
      comment: props.scheduleComment,
      dateStart: {
        string: "",
        date: null,
        isValid: false,
      },
    });

    // Way out binding for schedule
    watch(
      () => schedule.value,
      (newScheduleValue) => emit("update:scheduleValue", newScheduleValue)
    );
    watch(
      () => schedule.comment,
      (newScheduleComment) => emit("update:scheduleComment", newScheduleComment)
    );
    watch(
      () => schedule.dateStart.date,
      (newDate) => {
        if (
          props.scheduleDateStart &&
          dateToString(props.scheduleDateStart) === dateToString(newDate)
        ) {
          return;
        }

        emit("update:scheduleDateStart", newDate);
      }
    );

    // Way in binding for shedule
    watch(
      () => props.scheduleValue,
      (newScheduleValue) => (schedule.value = newScheduleValue)
    );
    watch(
      () => props.scheduleComment,
      (newScheduleComment) => (schedule.comment = newScheduleComment)
    );
    watch(
      () => props.scheduleDateStart,
      (newDate) => {
        if (!newDate) return;

        schedule.dateStart.string = dateToString(newDate);
      },
      { immediate: true }
    );

    // Inside component binding for dateStart
    watch(
      () => schedule.dateStart.string,
      (newDateString) => {
        // Validate new dob string
        if (/d|m|y|_/.test(newDateString) || newDateString.length === 0) return;

        if (
          schedule.dateStart.date &&
          dateToString(schedule.dateStart.date) === newDateString
        ) {
          return;
        }

        const newDate = stringToDate(newDateString);
        schedule.dateStart.date = newDate;
      }
    );

    const isValid = computed(() => {
      return schedule.dateStart.isValid && schedule.isValid;
    });
    // Way out binding for isValid
    watch(isValid, (newIsValid) => emit("update:isValid", newIsValid), {
      immediate: true,
    });

    const optionDateToday = {
      label: "Niezwłocznie",
      value: getTodayDateString(),
    };

    return {
      schedule,
      isValid,
      optionDateToday,
    };
  },
};
</script>

<style scoped>
.container {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 60px;
}
.wrapper-h1 {
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 20px;
}
.wrapper-schedule {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  column-gap: 40px;
  row-gap: 30px;
}
.wrapper-schedule:deep(.wrapper-schedule__comment-schedule) {
  width: 360px;
}

@media (max-width: 1200px) {
  .container {
    width: 100%;

    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 60px;
  }
  .wrapper-h1 {
    display: flex;
    flex-flow: column;
    align-items: center;
    row-gap: 20px;
  }
  .wrapper-schedule {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    column-gap: 40px;
    row-gap: 30px;
  }
  .wrapper-schedule:deep(.wrapper-schedule__comment-schedule) {
    width: 100%;
  }
}
</style>
