<template>
  <div class="container">
    <BaseH1
      :text="'Czy masz doświadczenie w opiece nad bliźniakami?'"
      class="h1 container__h1"
    />

    <InputRadioGroupHorizontal
      v-model="application.isExperienceTwins"
      :name="'is-experience-twins'"
      :options="optionsIsExperienceTwins"
      :width-small="true"
    />

    <TextHint :text="'Jest schowane, jeśli nie masz'" />
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import InputRadioGroupHorizontal from "@/components/UI/Base/InputRadioGroupHorizontal.vue";
import TextHint from "@/components/UI/TextHint.vue";

export default {
  components: {
    BaseH1,
    InputRadioGroupHorizontal,
    TextHint,
  },

  props: {
    isExperienceTwins: Boolean,
  },

  emits: ["push:experienceTwins", "pull:experienceTwins"],

  setup(props, { emit }) {
    const application = reactive({
      isExperienceTwins: props.isExperienceTwins,
    });

    watch(
      () => application.isExperienceTwins,
      (newIsExperienceTwins) => {
        if (newIsExperienceTwins === true) {
          emit("push:experienceTwins");
        }

        if (newIsExperienceTwins === false) {
          emit("pull:experienceTwins");
        }
      }
    );
    watch(
      () => props.isExperienceTwins,
      (newIsExperienceTwins) => {
        application.isExperienceTwins = newIsExperienceTwins;
      }
    );

    const optionsIsExperienceTwins = [
      {
        label: "Tak, mam",
        value: true,
      },
      {
        label: "Nie mam",
        value: false,
      },
    ];

    return {
      application,
      optionsIsExperienceTwins,
    };
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 80px;
}
.container__h1 {
  width: 420px;
  margin-top: -20px;
}
.wrapper-buttons {
  display: flex;
  flex-flow: row;
  column-gap: 40px;
}

@media (max-width: 1200px) {
  .container__h1 {
    width: 100%;
    margin-top: 0;
  }
}
</style>
