<template>
  <div class="container">
    <div class="wrapper-h1">
      <BaseH1 :text="'Wynagrodzenie'" />
      <TextDescription :text="'Napisz swoją minimalną stawkę za godzinę'" />
    </div>

    <div class="wrapper-fee">
      <InputTextFeeHour
        v-model="fee.hour.valueString"
        @update:is-valid-fee="fee.hour.isValid = $event"
      />
      <ButtonLink
        v-if="!fee.month.isShown"
        :label="'Dodaj opłatę za miesiąc'"
        class="show-fee-month-link wrapper-fee__show-fee-month-link"
        @click="fee.month.isShown = true"
      />
      <InputTextFeeMonth
        v-if="fee.month.isShown"
        v-model="fee.month.valueString"
        :prefix="'Od'"
      />
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import TextDescription from "@/components/UI/TextDescription.vue";
import InputTextFeeHour from "@/components/UI/InputTextFeeHour.vue";
import InputTextFeeMonth from "@/components/UI/InputTextFeeMonth.vue";
import ButtonLink from "@/components/UI/Base/ButtonLink.vue";

export default {
  components: {
    BaseH1,
    TextDescription,
    InputTextFeeHour,
    InputTextFeeMonth,
    ButtonLink,
  },

  props: {
    feeHour: Number,
    feeMonth: Number,
  },

  emits: ["update:feeHour", "update:feeMonth", "update:isValid"],

  setup(props, { emit }) {
    const fee = reactive({
      hour: {
        valueString: "",
        isValid: false,
      },
      month: {
        valueString: "",
        isShown: !!props.feeMonth,
      },
    });

    // Way out binding
    watch(
      () => fee.hour.valueString,
      (newFeeHour) => {
        const newFeeHourNumber = Number(newFeeHour);

        if (newFeeHourNumber === props.feeHour) return;

        emit("update:feeHour", newFeeHourNumber);
      }
    );
    watch(
      () => fee.month.valueString,
      (newFeeMonth) => {
        const newFeeMonthNumber = Number(newFeeMonth);

        if (newFeeMonthNumber === props.feeMonth) return;

        if (newFeeMonthNumber === 0) {
          emit("update:feeMonth", null);
          return;
        }

        emit("update:feeMonth", newFeeMonthNumber);
      }
    );

    // Way in binding
    watch(
      () => props.feeHour,
      (newFeeHour) => {
        if (newFeeHour === 0) return;

        const newFeeHourString = newFeeHour.toString();
        fee.hour.valueString = newFeeHourString;
      },
      { immediate: true }
    );
    watch(
      () => props.feeMonth,
      (newFeeMonth) => {
        if (newFeeMonth === 0 || newFeeMonth === null) return;

        const newFeeMonthNumber = newFeeMonth.toString();
        fee.month.valueString = newFeeMonthNumber;
      },
      { immediate: true }
    );

    const isValid = computed(() => {
      return fee.hour.isValid;
    });
    watch(isValid, (newIsValid) => emit("update:isValid", newIsValid), {
      immediate: true,
    });

    return {
      fee,
      isValid,
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 80px;
}
.wrapper-h1 {
  width: 360px;
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 20px;
}
.wrapper-fee {
  display: flex;
  flex-flow: row wrap;
  column-gap: 85px;
}
.wrapper-fee__show-fee-month-link {
  margin-left: -48px;
}

@media (max-width: 1200px) {
  .container {
    gap: 80px;
  }
  .wrapper-h1 {
    width: 100%;
    row-gap: 20px;
  }
  .wrapper-fee {
    flex-flow: row wrap;
    justify-content: center;
    row-gap: 40px;
  }
  .wrapper-fee__show-fee-month-link {
    margin-left: 0;
  }
}
</style>
